<main>
  <div class="main-div">
    <mat-card class="main-card">
      <mat-card-header>
        <mat-card-title>MyndsIT</mat-card-title>
        <mat-card-subtitle>Llevamos la tecnologia a las palmas de sus manos!</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content style="text-align: center;">
        <img src="assets/img/logo.png" style="margin-left: 180px;"/>
        <h1>404 not found</h1>
        <h2>Intenta de nuevo por favor</h2>
      </mat-card-content>
      <mat-card-actions style="text-align: center;">
        <app-menu-links class="'header-links list-reset m-0'"></app-menu-links>
      </mat-card-actions>
    </mat-card>
  </div>
</main>
