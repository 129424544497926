<section class="pricing section">
  <div class="container">
    <div class="pricing-inner section-inner has-top-divider">
      <h2 class="section-title mt-0 text-center">Pricing</h2>
      <div class="pricing-tables-wrap" ngsRevealSet [ngsSelector]="'.pricing-table'">
        <div class="pricing-table is-revealing">
          <div class="pricing-table-inner">
            <div class="pricing-table-main">
              <div class="pricing-table-header">
                <div class="pricing-table-title mt-12 mb-16 text-secondary">Lorem ipsum</div>
                <div class="pricing-table-price mb-24 pb-32"><span class="pricing-table-price-currency h3">$</span><span
                    class="pricing-table-price-amount h1">29</span></div>
              </div>
              <ul class="pricing-table-features list-reset text-xs mb-56">
                <li>
                  <span class="list-icon">
                    <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                        fill="#00C6A7" fill-rule="nonzero" />
                    </svg>
                  </span>
                  <span>Sed do eiusmod tempor cesa.</span>
                </li>
                <li>
                  <span class="list-icon">
                    <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                        fill="#00C6A7" fill-rule="nonzero" />
                    </svg>
                  </span>
                  <span>Sed do eiusmod tempor cesa.</span>
                </li>
                <li>
                  <span class="list-icon">
                    <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                        fill="#00C6A7" fill-rule="nonzero" />
                    </svg>
                  </span>
                  <span>Sed do eiusmod tempor cesa.</span>
                </li>
              </ul>
            </div>
            <div class="pricing-table-cta">
              <a class="button button-secondary button-block" href="#">Get started now</a>
            </div>
          </div>
        </div>
        <div class="pricing-table is-revealing">
          <div class="pricing-table-inner">
            <div class="pricing-table-main">
              <div class="pricing-table-header">
                <div class="pricing-table-title mt-12 mb-16 text-primary">Lorem ipsum</div>
                <div class="pricing-table-price mb-24 pb-32"><span class="pricing-table-price-currency h3">$</span><span
                    class="pricing-table-price-amount h1">129</span></div>
              </div>
              <ul class="pricing-table-features list-reset text-xs mb-56">
                <li>
                  <span class="list-icon">
                    <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                        fill="#00A2B8" fill-rule="nonzero" />
                    </svg>
                  </span>
                  <span>Sed do eiusmod tempor cesa.</span>
                </li>
                <li>
                  <span class="list-icon">
                    <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                        fill="#00A2B8" fill-rule="nonzero" />
                    </svg>
                  </span>
                  <span>Sed do eiusmod tempor cesa.</span>
                </li>
                <li>
                  <span class="list-icon">
                    <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                        fill="#00A2B8" fill-rule="nonzero" />
                    </svg>
                  </span>
                  <span>Sed do eiusmod tempor cesa.</span>
                </li>
                <li>
                  <span class="list-icon">
                    <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.3.3L5 9.6 1.7 6.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c.2.2.4.3.7.3.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0z"
                        fill="#00A2B8" fill-rule="nonzero" />
                    </svg>
                  </span>
                  <span>Sed do eiusmod tempor cesa.</span>
                </li>
              </ul>
            </div>
            <div class="pricing-table-cta">
              <a class="button button-primary button-block" href="#">Get started now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
