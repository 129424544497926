<div class="body-wrap boxed-container">
  <app-header></app-header>
  <main>
    <app-hero></app-hero>
    <app-clients></app-clients>
    <app-features-cards id="services"></app-features-cards>
    <app-features-extended id="about"></app-features-extended>
    <app-contact id="contact"></app-contact>
  </main>
  <app-footer></app-footer>
</div>
