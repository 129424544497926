<section class="pricing section">
  <div class="container">
    <div class="pricing-inner section-inner has-top-divider">
      <h2 class="section-title mt-0 text-center">Contacto</h2>
      <div class="pricing-tables-wrap" ngsRevealSet [ngsSelector]="'.pricing-table'">
        <div class="pricing-table is-revealing">
          <div class="pricing-table-inner">
            <div class="pricing-table-main">
              <div class="pricing-table-header">
                <div class="pricing-table-title mt-12 mb-16 text-primary">Queremos ayudarte!</div>
                <!--
                <div class="pricing-table-price mb-24 pb-32">
                  <span class="pricing-table-price-currency h3">$</span><span
                    class="pricing-table-price-amount h1">129</span>
                  </div>
                -->
              </div>
              <ul class="pricing-table-features list-reset text-xs mb-56">
                <li>
                  Contamos con varios metodos de contacto, selecciona el que mas te parezca para contarnos sobre tu proyecto!
                </li>
              </ul>
              <div class="pricing-table-cta">
                    <a class="button button-primary button-block" href="https://m.me/MyndsIT">Chat de Messenger</a>
              </div>
              <p>&nbsp;</p>
              <div class="pricing-table-cta">
                    <a class="button button-primary button-block" href="mailto:info@myndsit.com">Correo electronico</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
