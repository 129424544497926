<section class="features-extended section" [ngsRevealSet]="{reset: true}" [ngsSelector]="'.feature-extended-image'">
    <div class="container">
        <div class="features-extended-inner section-inner has-top-divider">
            <div class="features-extended-header text-center">
                <div class="container-sm">
                    <h2 class="section-title mt-0">Nuestros proyectos</h2>
                    <p class="section-paragraph"> Nuestro equipo tiene habilidades técnicas actualizadas constantemente, esto nos vuelve una de las empresas más competitivas y de mejor calidad en el pais.</p>
                  </div>
            </div>
            <div class="feature-extended">
                <div class="feature-extended-image is-revealing">
                  <img src="assets/img/appdev.png" width="480px" height="360px" alt="Desarrollo de aplicaciones">
                </div>
                <div class="feature-extended-body">
                    <h3 class="mt-0">Desarrollo de aplicaciones</h3>
                    <p>Desarrollamos Apps para iPhone, Android y Windows Phone.
                      Adicionalmente, podemos apoyarle con el soporte y mantenimiento de sus aplicaciones  previamente desarrolladas.</p>
                </div>
            </div>
            <div class="feature-extended">
                <div class="feature-extended-image is-revealing">
                  <img src="assets/img/webdev.png" width="480px" height="360px" alt="Desarrollo Web">
                </div>
                <div class="feature-extended-body">
                    <h3 class="mt-0">Desarrollo Web</h3>
                    <p>Tenemos muy claro que las páginas web son la nueva tarjeta de presentación de los negocios,
                      tenemos experiencia en creacion y administracion de sitios para E-commerce,
                      paginas personalizadas o incluso hasta portafolios en linea</p>
                </div>
            </div>
            <div class="feature-extended">
                <div class="feature-extended-image is-revealing">
                  <img src="assets/img/itconsulting.png" width="480px" height="360px" alt="Consultoria de it">
                </div>
                <div class="feature-extended-body">
                    <h3 class="mt-0">Servicios de consultoria</h3>
                    <p>Brindamos servicios de consultoria y asesoria en tecnologias de la informacion, nuestro objetivo es ayudarle a llevar a cabo sus proyectos de la manera mas eficiente en cuanto a costos y resultados!</p>
                </div>
            </div>
        </div>
    </div>
</section>
