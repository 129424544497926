<app-header></app-header>
<main>
<div class="main-div">
  <mat-card class="main-card">
    <mat-card-header>
      <mat-card-title>MyndsIT ~ Privacy Policy</mat-card-title>
      <mat-card-subtitle>Politica de privacidad par anuestro servicio {{appName}}!</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="text-align: justify">
      <app-android-apps *ngIf="type === 'android'" [appName]="appName"></app-android-apps>
      <app-android-apps *ngIf="type === 'web'" [appName]="appName"></app-android-apps>
    </mat-card-content>
    <mat-card-actions style="text-align: center;">
      <a href="https://fb.com/myndsit" target="_blank">
        <button  mat-raised-button color="primary">Facebook</button>
      </a>
    </mat-card-actions>
  </mat-card>
</div>
</main>
<app-footer></app-footer>

