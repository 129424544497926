<section class="clients section" style="padding-top: 100px;">
  <h1 class="hero-title h2-mobile mt-0 is-revealing" style="text-align: center;" ngsReveal>Nuestros clientes</h1>
  <div class="container">
    <div class="clients-inner section-inner has-top-divider">
      <div class="container-sm">
        <ul class="list-reset mb-0" [ngsRevealSet]="{ reset:true}" [ngsSelector]="'.client'">
          <li class="client" *ngFor="let client of clients">
            <span class="screen-reader-text">Instagram</span>
            <a href="{{client.url}}" target="_blank">
              <img [src]="client.logo" alt="{{client.name}}" style="max-height: 80px"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
