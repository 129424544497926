<footer class="site-footer">
  <div class="container">
    <div class="site-footer-inner">
      <div class="brand footer-brand" style="margin-bottom: 0px !important;">
        <img src="./assets/img/logo.png" style="height: 40px;" />
      </div>
      <div class="footer-social-links list-reset" style="margin-bottom: 0px !important;">
        <app-social-icons></app-social-icons>
      </div>
      <div class="footer-links list-reset">
        <app-menu-links  [class]="'footer-links list-reset'"></app-menu-links>
      </div>
      <div class="footer-copyright">&copy; {{year}} MyndsIT.</div>
    </div>
  </div>
</footer>
