<header class="site-header">
  <app-floating-squares></app-floating-squares>
  <div class="container">
    <div class="site-header-inner">
      <a href="/">
        <div class="brand header-brand">
          <img src="./assets/img/logo.png" style="height: 70px;"/>
        </div>
      </a>
      <app-menu-links [class]="'header-links list-reset m-0'"></app-menu-links>
    </div>
  </div>
</header>
