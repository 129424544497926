<section class="features section text-center">
  <div class="container" [ngsRevealSet]="{reset: true}" [ngsSelector]="'.feature'">
    <div class="features-inner section-inner has-top-divider">
      <h2 class="section-title mt-0">Servicios</h2>
      <div class="features-wrap">
        <div class="feature is-revealing">
          <div class="feature-inner">
            <div class="feature-icon">
              <mat-icon aria-hidden="false" style="font-size: 50px;">code</mat-icon>
            </div>
            <h4 class="feature-title h3-mobile">Desarrollo de aplicaciones</h4>
            <p class="text-sm">Creamos aplicaciones a la medida para solventar cualquier necesidad de negocio, tanto moviles (Android y iOS) como de escritorio.</p>
          </div>
        </div>
        <div class="feature is-revealing">
          <div class="feature-inner">
            <div class="feature-icon">
              <mat-icon aria-hidden="false" style="font-size: 50px;">language</mat-icon>
            </div>
            <h4 class="feature-title h3-mobile">Creacion de sitios web</h4>
            <p class="text-sm">Tenemos experiencia en creacion de sitios web, tanto para e-commerce como para empresas de cualquier mercado, ya sea solo la plantilla o el servicio completo, aca estamos!</p>
          </div>
        </div>
        <div class="feature is-revealing">
          <div class="feature-inner">
            <div class="feature-icon">
              <mat-icon aria-hidden="false" style="font-size: 50px;">cloud_circle</mat-icon>
            </div>
            <h4 class="feature-title h3-mobile">Servicios de infraestructura</h4>
            <p class="text-sm">Creamos, administramos y optimizamos tu infraestructura en la nube. Nunca ha sido tan facil llevar tu negocio al siguiente nivel!</p>
          </div>
        </div>
        <div class="feature is-revealing">
          <div class="feature-inner">
            <div class="feature-icon">
              <mat-icon aria-hidden="false" style="font-size: 50px;">fingerprint</mat-icon>
            </div>
            <h4 class="feature-title h3-mobile">Servicios a la medida</h4>
            <p class="text-sm">Tienes algun proyecto o idea que involucra tecnologia y no tienes idea de como realizarlo? Aqui estamos para ayudarte!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
