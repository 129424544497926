<div class="main-div">
  <mat-card class="main-card">
    <mat-card-header>
      <mat-card-title>MyndsIT</mat-card-title>
      <mat-card-subtitle>Llevamos la tecnologia a las palmas de sus manos!</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style="text-align: center;">
      <img src="assets/img/mynds-logo.png"/>
      <h1>Estamos trabajando en un sitio completamente nuevo, te invitamos a seguirnos en nuestras redes sociales</h1>
    </mat-card-content>
    <mat-card-actions style="text-align: center;">
      <a href="https://fb.com/myndsit">
        <button mat-button>Facebook</button>
      </a>
    </mat-card-actions>
  </mat-card>
</div>
