import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-web-apps',
  templateUrl: './web-apps.component.html',
  styleUrls: ['./web-apps.component.scss']
})
export class WebAppsComponent implements OnInit {
  @Input() appName: string;

  constructor() { }

  ngOnInit(): void {
  }

}
